<template>
  <div>
    <img  v-if="showImg" style="width:100%;" src="../../assets/images/iswechat.jpeg"/>
    <div class="vip" @click="payVip1">
      <img src="../../../src/assets/images/vip1.png"/>
      <div class="content" style="border-top: 1px solid rgb(233,233,234);border-right: 1px solid rgb(233,233,234);border-bottom: 1px solid rgb(233,233,234);">
        <p style="text-decoration:underline;font-size:0.4rem;color:rgb(210, 123, 47);text-indent:1.2em;">SVIP1</p>
        <p>【包月】特邀码师资料，全部特邀码师资料可免费查看！</p>
        <p class="svip" v-if="vip1.isAvailable">
          <span>已拥有 </span>
          <span>{{vip1.startDate | setDateMMdd}}-{{vip1.endDate | setDateMMdd}}</span>
          <!-- <span>{{test | setDateMMdd}}</span> -->
        </p>
        <p v-else class="button">
          <span class="spantext">马上成为会员</span>
          <span class="money">￥{{vip1.price}}元</span>
        </p>
       
      </div>
    </div> 
    <div class="vip" @click="payVip2" >
      <div class="content" style="border-top: 1px solid rgb(233,233,234);border-left: 1px solid rgb(233,233,234);border-bottom: 1px solid rgb(233,233,234);">
        <p style="text-decoration:underline;font-size:0.4rem;color:rgb(210, 123, 47);text-indent:1.2em;">SVIP2</p>
        <p>【包月】彩友圈码师，全部彩友圈码师资料可免费查看！</p>
        <p class="svip" v-if="vip2.isAvailable">
          <span>已拥有 </span>
          <span>{{vip2.startDate | setDateMMdd}}-{{vip2.endDate | setDateMMdd}}</span>
        </p>
        <p v-else class="button">
          <span class="spantext">马上成为会员</span>
          <span class="money">￥{{vip2.price}}元</span>

        </p>
      </div>
      <img src="../../../src/assets/images/vip2.png"/>

    </div>
    <div class="vip" @click="payVip3" >
      <img src="../../../src/assets/images/vip3.png"/>
      <div class="content" style="border-top: 1px solid rgb(233,233,234);border-right: 1px solid rgb(233,233,234);border-bottom: 1px solid rgb(233,233,234);">
        <p style="text-decoration:underline;font-size:0.4rem;color:rgb(210, 123, 47);text-indent:1.2em;">SVIP3</p>
        <p>【包月】好劲抽所有资料都可以查看，拥有所有权限！</p>
        <p class="svip" v-if="vip3.isAvailable">
          <span>已拥有 </span>
          <span>{{vip3.startDate | setDateMMdd}}-{{vip3.endDate | setDateMMdd}}</span>
        </p>
        <p v-else class="button">
          <span class="spantext">马上成为会员</span>
          <span class="money">￥{{vip3.price}}元</span>
        </p>
      </div>
    </div>
    
  </div>
</template>

<script>

import Moment from 'moment';
import isWeixin from "@/utils/utils.js";

export default {
  data(){
    return{
      showImg:false,
      test:'2023/02/07',
      systemId:'100001',
      userid:this.$route.query.userid,
      token:this.$route.query.access_token || '',
      price1:'',
      settingVipId1:'',
      price2:'',
      settingVipId2:'',
      price3:'',
      settingVipId3:'',
      vip1:{},
      vip2:{},
      vip3:{},
    }
  },
  methods:{
    async getsvipinfo(){
      const res = await this.$HTTP.get(this, this.$API['API_SVIP_INFO'],{userid : this.userid , system_id : this.systemId}, {}, true)
                                if(res.statusCode == 200){
                                  //  console.log('后台返回的svip列表',res)
                                   res.data.forEach((item)=>{
                                      if(item.vipLevel===10){//svip1
                                        this.price1=item.price
                                        this.settingVipId1=item.settingVipId
                                        this.vip1=item
                                        // console.log(this.vip1)
                                      }
                                      if(item.vipLevel===11){//svip2
                                        this.price2=item.price
                                        this.settingVipId2=item.settingVipId
                                        this.vip2=item
                                        // console.log(this.vip2)

                                      }
                                      if(item.vipLevel===2){//svip3
                                        this.price3=item.price
                                        this.settingVipId3=item.settingVipId
                                        this.vip3=item
                                        // console.log(this.vip3)

                                      }
                                   })
                                }
    },
    payVip1(){
      if(this.vip1.isAvailable){
        console.log('已拥有该svip，无需再次充值')

      }else{
        this.topay(this.price1,this.settingVipId1,this.token,this.userid)
      }
      
    },
    payVip2(){
      if(this.vip2.isAvailable){
        console.log('已拥有该svip，无需再次充值')

      }else{
        this.topay(this.price2,this.settingVipId2,this.token,this.userid)
      }
    },
    payVip3(){
      if(this.vip3.isAvailable){
        console.log('已拥有该svip，无需再次充值')

      }else{
        this.topay(this.price3,this.settingVipId3,this.token,this.userid)
      }
    },
    async topay(n,vipid,token,userid){
      console.log(n,userid)
      let args = {
            appId:"wxfeb0f4d0858263a3",
            artId: "101",
            bodyContent: "付费阅读",
            openid: "",
            payId: 3,
            buyType: 6,
            price: n,
            titles: "付费阅读",
            transactionType: "H5",
            // money: n,
            userId: userid,
            extendJson: "{userId:"+userid+",settingVipId:"+vipid+"}",
          }
          let res
        if(token===''){
          res = await this.$HTTP.post(this, 'API_PAYMENT_PRODUCT', args, {}, true)

        }else{
          res = await this.$HTTP.post(this, 'API_PAYMENT_PRODUCT', args, { headers: { access_token: token } }, true)

        }
        // if (res.statusCode === 200) {
        //     this.$toast('充值成功');
        //
        // }
        if(res && res.statusCode === 200){
          this.$toast.clear()
          let newUrl = res.prepayUrl;
          window.location.href = newUrl;
        } else {
          console.log(res.responseDescription);
        }
    },
  },
  filters: {
    setDateMMdd(vle) {
      if (vle) { return Moment(vle).format('M月DD') }
      return '';
    }
  },
 
  mounted(){
    let isWeiXin = isWeixin();
        if (isWeiXin) {
            this.showImg=true
        }
    if (
      (this.$store.state.common.loginInfo.access_token===null || this.$store.state.common.loginInfo.access_token === '' || this.$store.state.common.loginInfo.access_token === undefined)&&
      (this.token === null || this.token ==='' || this.token === undefined)
    ) {
      this.$router.replace('/login')
    }
    // console.log(null||undefined||1)
    this.getsvipinfo()
  },
}
</script>

<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
}
.vip{
  margin: 0.5rem;
  text-align: center;
  height: 2rem;
 
  img{
    display: inline-block;
    float: left;
    width: 39.5%;
    height: 2rem;
  }
  .content{
    float: left;
    display: inline-block;
    width: 60%;
    height: 97.5%;
    background-color: rgb(248, 248, 248);
    .svip{
      margin: 0.1rem 0 0 0.6rem;
      text-indent:0em;
      color:rgb(210, 123, 47);
    }
    .button{
      margin: 0.1rem 0 0 0.6rem;
      text-indent:0em;
      .spantext{
        background-color: red;
        color: white;
      }
      .money{
        padding-left: 0.2rem;
        color:rgb(210, 123, 47);
      }
      
    }
    p{
      text-indent:2em;
      font-size: 0.3rem;
      text-align: left;
      line-height: 0.4rem;
    }
  }
  
}


.button1{
  position: relative;
    // top: -1.1rem;
    right: -1.5rem;
}
.button2{
  position: relative;
    // top: -1.1rem;
    right: 1.2rem;
}
.button3{
  position: relative;
    // top: -1.1rem;
    right: -2.5rem;
}

</style>